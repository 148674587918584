angular.module('linshare.components', ['ngMaterial', 'linshare.utils']);

require('./constants');
require('./appendToBody/appendToBody');
require('./autocompleteUsers/autocompleteUserRestService');
require('./autocompleteUsers/autocompleteUsersController');
require('./autocompleteUsers/autocompleteUsersDirective');
require('./browse/browseController');
require('./browse/browseService');
require('./debugModeSwitcher/debugModeSwitcherComponent');
require('./debugModeSwitcher/debugModeSwitcherController');
require('./documentPreviewButton/documentPreviewButtonComponent');
require('./documentPreviewButton/documentPreviewButtonController');
require('./documentPreviewButton/services/documentPreviewService');
require('./documentPreviewButton/components/documentPreviewMode/documentPreviewModeComponent');
require('./documentPreviewButton/components/documentPreviewMode/documentPreviewModeController');
require('./documentPreviewButton/components/documentPreviewNavigation/documentPreviewNavigationComponent');
require('./documentPreviewButton/components/documentPreviewNavigation/documentPreviewNavigationController');
require('./documentPreviewButton/components/documentPreviewToolbar/documentPreviewToolbarComponent');
require('./documentPreviewButton/components/documentPreviewToolbar/documentPreviewToolbarController');
require('./documentPreviewButton/components/documentPreviewToolbar/components/documentPreviewToolbarButtons/documentPreviewToolbarButtonsComponent');
require('./dragAndDrop/dragAndDropDirective');
require('./embedPdf/embedPdfComponent');
require('./fabButton/fabButtonDirective');
require('./fabButton/directives/fabLink/fabLinkDirective');
require('./filterBox/filterBoxController');
require('./filterBox/filterBoxDirective');
require('./filterBox/filterBoxService');
require('./filters/readableSizeFilter');
require('./filters/relativeTimeFilter');
require('./filters/remainingTimeFilter');
require('./filters/translateIfFilter');
require('./languageSelector/languageSelectorComponent');
require('./languageSelector/languageSelectorController');
require('./ownerLabel/ownerLabelDirective');
require('./ownerLabel/ownerLabelService');
require('./passwordValidator/passwordInputHintsComponent');
require('./passwordValidator/passwordValidatorDirective');
require('./qrcode/qrcodeDirective');
require('./services/languageService');
require('./services/unit-service');
require('./sidebar/sidebarAuditActionsTabComponent');
require('./sidebar/sidebarAuditActionsTabController');
require('./sidebar/sidebarService');
require('./sidebar/sidebarDescriptionInput');
require('./tableParams/services/tableParamsService');
require('./toast/toastController');
require('./toast/toastService');
require('./working-date-picker/working-date-picker.directive');
require('./dateTimePicker/dateTimePicker');
require('./dateTimePicker/dateTimePickerController');
require('./dateTimePicker/hourPickerValidator');